import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCUnlFacbCib5cY0xuceVdhUyddNSN7Bw0',
  authDomain: 'est-digital-id.firebaseapp.com',
  databaseURL: 'https://est-digital-id.firebaseio.com',
  projectId: 'est-digital-id',
  storageBucket: 'est-digital-id.appspot.com',
  messagingSenderId: '233631773184',
  appId: '1:233631773184:web:5821dfafeb2ecd5f8ddee5',
  measurementId: 'G-2L4PPVXC8X',
};

const didApp = firebase.initializeApp(firebaseConfig, 'DID');
const didDB = didApp.firestore();
const didNotifications = didDB.collection('messages');

export { didNotifications };
