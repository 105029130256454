import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const lmsRoutes: RouteConfig = {
  path: '/lms',
  component: Layout,
  redirect: '/lms/stripe',
  name: 'LMS',
  meta: {
    title: 'LMS',
    icon: 'tree-table',
    requiresAuth: true,
    action: 'lms:view',
  },
  children: [
    {
      path: 'stripe',
      component: () => import(/* webpackChunkName: "lms-stripe" */ '@/views/lms/stripe.vue'),
      name: 'Stripe',
      meta: {
        title: 'Stripe Transactions',
        icon: 'transaction',
        action: 'lms:stripe',
      },
    },
    {
      path: 'students',
      component: () => import(/* webpackChunkName: "lms-export-users" */ '@/views/lms/students.vue'),
      name: 'Students',
      meta: {
        title: 'Students',
        icon: 'peoples',
        action: 'lms:students',
      },
    },
  ],
};

export default lmsRoutes;
