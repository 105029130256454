/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Layer 2"><g data-name="settings-2"><path pid="0" d="M12.94 22h-1.89a1.68 1.68 0 01-1.68-1.68v-1.09a.34.34 0 00-.22-.29.38.38 0 00-.41 0l-.74.8a1.67 1.67 0 01-2.37 0L4.26 18.4a1.66 1.66 0 01-.5-1.19 1.72 1.72 0 01.5-1.21l.74-.74a.34.34 0 000-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 012 12.94v-1.89a1.68 1.68 0 011.68-1.68h1.09a.34.34 0 00.29-.22.38.38 0 000-.41L4.26 8a1.67 1.67 0 010-2.37L5.6 4.26a1.65 1.65 0 011.18-.5 1.72 1.72 0 011.22.5l.74.74a.34.34 0 00.37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0111.06 2H13a1.68 1.68 0 011.68 1.68v1.09a.34.34 0 00.22.29.38.38 0 00.41 0l.69-.8a1.67 1.67 0 012.37 0l1.37 1.34a1.67 1.67 0 01.5 1.19 1.63 1.63 0 01-.5 1.21l-.74.74a.34.34 0 000 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0122 11.06V13a1.68 1.68 0 01-1.68 1.68h-1.09a.34.34 0 00-.29.22.34.34 0 000 .37l.77.77a1.67 1.67 0 010 2.37l-1.31 1.33a1.65 1.65 0 01-1.18.5 1.72 1.72 0 01-1.19-.5l-.77-.74a.34.34 0 00-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0112.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 011.46-2.14 2.36 2.36 0 012.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 01-.48-2.56 2.33 2.33 0 012.14-1.45H20v-1.29h-.77a2.33 2.33 0 01-2.14-1.46 2.36 2.36 0 01.47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 01-4-1.67V4h-1.3v.77a2.33 2.33 0 01-1.46 2.14 2.36 2.36 0 01-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 01-1.67 4H4v1.26h.77a2.33 2.33 0 012.14 1.46 2.36 2.36 0 01-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 014 1.67z" data-name="&lt;Group&gt;"/><path pid="1" d="M12 15.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zm0-5a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5z"/></g></g>'
  }
})
