import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const elfRoutes: RouteConfig = {
  path: '/elf',
  component: Layout,
  redirect: '/elf/configuration',
  name: 'Elf',
  meta: {
    title: 'ELF',
    icon: 'component',
    requiresAuth: true,
    action: 'elf:view',
  },
  children: [
    {
      path: 'configuration',
      component: () => import(/* webpackChunkName: "elf-configuration" */ '@/views/elf/configuration.vue'),
      name: 'ElfConfiguration',
      meta: {
        title: 'Configuration',
        icon: 'sliders',
        action: 'elf:configure',
      },
    },
    {
      path: 'exam-builder',
      component: () => import(/* webpackChunkName: "elf-exam-builder" */ '@/views/elf/builder.vue'),
      name: 'ElfExamBuilder',
      meta: {
        title: 'Exam Builder',
        icon: 'ui-radios',
        action: 'elf:builder',
      },
    },
    {
      path: 'exam-review',
      component: () => import(/* webpackChunkName: "elf-exam-review" */ '@/views/elf/review.vue'),
      name: 'ElfExamReview',
      meta: {
        title: 'Exam Review',
        icon: 'binoculars-fill',
        action: 'elf:review',
      },
    },
  ],
};

export default elfRoutes;
