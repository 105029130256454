import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const questionsRouter: RouteConfig = {
  path: '/questions',
  component: Layout,
  redirect: '/questions/search',
  name: 'Questions',
  meta: {
    title: 'Questions',
    icon: 'nested',
    requiresAuth: true,
    action: 'questions:view',
  },
  children: [
    {
      path: 'search',
      component: () => import(/* webpackChunkName: "search" */ '@/views/questions/search/index.vue'),
      name: 'Search',
      meta: {
        title: 'Search',
        icon: 'search',
        action: 'questions:search',
      },
    },
    {
      path: 'lesson-qa',
      component: () => import(/* webpackChunkName: "questions-layout" */ '@/views/questions/index.vue'),
      redirect: '/questions/lesson-qa/overview',
      name: 'Lesson',
      meta: {
        title: 'Lesson Q&A',
        class: 'LessonQA',
        action: 'questions:view',
      },
      children: [
        {
          path: 'overview',
          component: () => import(/* webpackChunkName: "lesson-qa-overview" */ '@/views/questions/lesson/index.vue'),
          name: 'Overview',
          meta: {
            title: 'Overview',
            icon: 'view-list',
            action: 'questions:view',
          },
        },
        {
          path: 'create',
          name: 'LessonCreate',
          component: () => import(/* webpackChunkName: "lesson-qa-create" */ '@/views/questions/lesson/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus-square',
            action: 'questions:create',
          },
        },
        {
          path: 'edit/:id',
          name: 'LessonEdit',
          component: () => import(/* webpackChunkName: "lesson-qa-edit" */ '@/views/questions/lesson/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            action: 'questions:edit',
          },
        },
        {
          path: 'export',
          name: 'LessonExport',
          component: () => import(/* webpackChunkName: "lesson-qa-export" */ '@/views/questions/lesson/export.vue'),
          meta: {
            title: 'Export',
            icon: 'save',
            action: 'questions:export',
          },
        },
      ],
    },
    {
      path: 'practice-exam',
      component: () => import(/* webpackChunkName: "questions-layout" */ '@/views/questions/index.vue'),
      redirect: '/questions/practice-exam/overview',
      name: 'Practice Exam',
      meta: {
        title: 'Practice Exam',
        class: 'PracticeExam',
        requiresAuth: true,
        action: 'questions:view',
      },
      children: [
        {
          path: 'overview',
          component: () =>
            import(/* webpackChunkName: "practice-exam-overview" */ '@/views/questions/practice-exam/index.vue'),
          name: 'PracticeExamOverview',
          meta: {
            title: 'Overview',
            icon: 'view-list',
            action: 'questions:view',
          },
        },
        {
          path: 'create',
          name: 'PracticeExamCreate',
          component: () =>
            import(/* webpackChunkName: "practice-exam-create" */ '@/views/questions/practice-exam/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus-square',
            action: 'questions:create',
          },
        },
        {
          path: 'edit/:id',
          name: 'PracticeExamEdit',
          component: () =>
            import(/* webpackChunkName: "practice-exam-edit" */ '@/views/questions/practice-exam/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            action: 'questions:edit',
          },
        },
        {
          path: 'export',
          name: 'PracticeExamExport',
          component: () =>
            import(/* webpackChunkName: "practice-exam-export" */ '@/views/questions/practice-exam/export.vue'),
          meta: {
            title: 'Export',
            icon: 'save',
            requiresAuth: true,
            action: 'questions:export',
          },
        },
      ],
    },
    {
      path: 'targeted-review',
      component: () => import(/* webpackChunkName: "questions-layout" */ '@/views/questions/index.vue'),
      redirect: '/questions/targeted-review/overview',
      name: 'TargetedReview',
      meta: {
        title: 'Targeted Review',
        class: 'TargetedReview',
        action: 'questions:view',
      },
      children: [
        {
          path: 'overview',
          component: () =>
            import(/* webpackChunkName: "targeted-review-overview" */ '@/views/questions/targeted-review/index.vue'),
          name: 'TargetedReviewOverview',
          meta: {
            title: 'Overview',
            icon: 'view-list',
            action: 'questions:view',
          },
        },
        {
          path: 'create',
          name: 'TargetedReviewCreate',
          component: () =>
            import(/* webpackChunkName: "targeted-review-create" */ '@/views/questions/targeted-review/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus-square',
            action: 'questions:create',
          },
        },
        {
          path: 'edit/:id',
          name: 'TargetedReviewEdit',
          component: () =>
            import(/* webpackChunkName: "targeted-review-edit" */ '@/views/questions/targeted-review/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            action: 'questions:edit',
          },
        },
        {
          path: 'export',
          name: 'TargetedReviewExport',
          component: () =>
            import(/* webpackChunkName: "targeted-review-export" */ '@/views/questions/targeted-review/export.vue'),
          meta: {
            title: 'Export',
            icon: 'save',
            action: 'questions:export',
          },
        },
      ],
    },
    {
      path: 'true-false',
      component: () => import(/* webpackChunkName: "questions-layout" */ '@/views/questions/index.vue'),
      redirect: '/questions/true-false/overview',
      name: 'TrueFalse',
      meta: {
        title: 'True/False',
        class: 'TrueFalse',
        action: 'questions:view',
      },
      children: [
        {
          path: 'overview',
          component: () =>
            import(/* webpackChunkName: "true-false-overview" */ '@/views/questions/true-false/index.vue'),
          name: 'TrueFalseOverview',
          meta: {
            title: 'Overview',
            icon: 'view-list',
            action: 'questions:view',
          },
        },
        {
          path: 'create',
          name: 'TrueFalseCreate',
          component: () => import(/* webpackChunkName: "true-false-create" */ '@/views/questions/true-false/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus-square',
            action: 'questions:create',
          },
        },
        {
          path: 'edit/:id',
          name: 'TrueFalseEdit',
          component: () => import(/* webpackChunkName: "true-false-edit" */ '@/views/questions/true-false/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            action: 'questions:edit',
          },
        },
        {
          path: 'export',
          name: 'TrueFalseExport',
          component: () =>
            import(/* webpackChunkName: "true-false-export" */ '@/views/questions/true-false/export.vue'),
          meta: {
            title: 'Export',
            icon: 'save',
            action: 'questions:export',
          },
        },
      ],
    },
    {
      path: 'learning-feature',
      component: () => import(/* webpackChunkName: "questions-layout" */ '@/views/questions/index.vue'),
      redirect: '/question/learning-feature/overview',
      name: 'LearningFeature',
      meta: {
        title: 'Learning Feature',
        class: 'LearningFeature',
        action: 'questions:view',
      },
      children: [
        {
          path: 'overview',
          component: () =>
            import(/* webpackChunkName: "learning-feature-overview" */ '@/views/questions/learning-feature/index.vue'),
          name: 'LearningFeatureOverview',
          meta: {
            title: 'Overview',
            icon: 'view-list',
            action: 'questions:view',
          },
        },
        {
          path: 'create',
          name: 'LearningFeatureCreate',
          component: () =>
            import(/* webpackChunkName: "learning-feature-create" */ '@/views/questions/learning-feature/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus-square',
            action: 'questions:create',
          },
        },
        {
          path: 'edit/:id',
          name: 'LearningFeatureEdit',
          component: () =>
            import(/* webpackChunkName: "learning-feature-edit" */ '@/views/questions/learning-feature/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            action: 'questions:edit',
          },
        },
        {
          path: 'export',
          name: 'LearningFeatureExport',
          component: () =>
            import(/* webpackChunkName: "learning-feature-export" */ '@/views/questions/learning-feature/export.vue'),
          meta: {
            title: 'Export',
            icon: 'save',
            action: 'questions:export',
          },
        },
      ],
    },
    {
      path: 'exports',
      component: () => import(/* webpackChunkName: "questions-exports" */ '@/views/questions/exports/index.vue'),
      name: 'Exports',
      meta: {
        title: 'Exports',
        icon: 'zip',
        action: 'questions:export',
      },
    },
  ],
};

export default questionsRouter;
