import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const toolsRoutes: RouteConfig = {
  path: '/tools',
  component: Layout,
  redirect: '/tools/users',
  meta: {
    title: 'Tools',
    icon: 'settings',
    requiresAuth: true,
    action: 'tools:view',
  },
  children: [
    {
      path: 'users',
      name: 'UsersList',
      component: () => import(/* webpackChunkName: "tools-users" */ '@/views/tools/users/index.vue'),
      meta: {
        title: 'Users',
        icon: 'peoples',
        action: 'users:view',
      },
    },
    {
      path: 'trash',
      name: 'Trash',
      component: () => import(/* webpackChunkName: "tools-trash" */ '@/views/tools/trash/index.vue'),
      meta: {
        title: 'Trash',
        icon: 'trash',
        action: 'trash:view',
      },
    },
    {
      path: 'maintenance',
      name: 'Maintenance',
      component: () => import(/* webpackChunkName: "tools-maintenance" */ '@/views/tools/maintenance/index.vue'),
      meta: {
        title: 'Maintenance',
        icon: 'example',
        action: 'maintenance:view',
      },
    },
  ],
};

export default toolsRoutes;
