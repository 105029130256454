import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

/* Layout */
import Layout from '@/layout/index.vue';

/* Router modules */
import questionsRouter from './modules/questions';
import elfRouter from './modules/elf';
import lmsRouter from './modules/lms';
import toolsRouter from './modules/tools';
import digitalRouter from './modules/digitalid';
// import tableRouter from './modules/table';
// import tableRouter from './modules/table';
// import nestedRouter from './modules/nested';

Vue.use(VueRouter);

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'Dashboard',
          icon: 'dashboard',
          affix: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/daily-question',
    component: Layout,
    redirect: '/daily-question/today',
    meta: {
      requiresAuth: true,
      action: 'daily:view',
    },
    children: [
      {
        path: 'today',
        component: () => import(/* webpackChunkName: "daily-question" */ '@/views/daily-question/index.vue'),
        name: 'DailyQuestionToday',
        meta: {
          title: 'Daily Question',
          icon: 'star',
          affix: true,
          requiresAuth: true,
          action: 'daily:view',
        },
      },
    ],
  },
  digitalRouter,
  elfRouter,
  lmsRouter,
  questionsRouter,
  toolsRouter,
  {
    path: '/user',
    component: Layout,
    redirect: '/user/bookmarks',
    meta: { title: 'User', icon: 'example', hidden: true, requiresAuth: true },
    children: [
      {
        path: 'bookmarks',
        name: 'Bookmarks',
        component: () => import(/* webpackChunkName: "bookmark" */ '@/views/user/bookmark.vue'),
        meta: {
          title: 'Bookmarks',
          icon: 'example',
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/user/profile.vue'),
        meta: {
          title: 'Profile',
          icon: 'example',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true },
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true },
  },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes: RouteConfig[] = [
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true },
  },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
