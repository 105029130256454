/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'binoculars-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 1A1.5 1.5 0 003 2.5V3h4v-.5A1.5 1.5 0 005.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 00.276.447l.895.447A1.5 1.5 0 0115 7.118V13H9v-1.5a.5.5 0 01.146-.354l.854-.853V9.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.793l.854.853A.5.5 0 017 11.5V13H1V7.118a1.5 1.5 0 01.83-1.342l.894-.447A.5.5 0 003 4.882V4h4zM1 14v.5A1.5 1.5 0 002.5 16h3A1.5 1.5 0 007 14.5V14H1zm8 0v.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0110.5 1h1A1.5 1.5 0 0113 2.5V3z"/>'
  }
})
