import { UserModule } from '@/store/modules/user';

export default {
  // The install method will be called with the Vue constructor as the first argument, along with possible options
  install(Vue: {
    prototype: { $can: (action: string) => boolean };
    directive: (arg0: string, arg1: (el: any, bindings: any, vnode: any) => void) => void;
  }) {
    function commentNode(el: any, vnode: any) {
      const comment = document.createComment(' ');

      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });

      vnode.text = ' ';
      vnode.elm = comment;
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }

    // Add $plugin instance method directly to Vue components
    Vue.prototype.$can = (action: string) => Can(action);

    Vue.directive('can', (el: any, bindings: any, vnode: any) => {
      const behavior = bindings.modifiers.disabled ? 'disable' : 'hide';
      if (!UserModule.claims.permissions.includes(bindings.arg) && !UserModule.claims.Admin) {
        if (behavior === 'hide') {
          commentNode(el, vnode);
        } else if (behavior === 'disable') {
          el.disabled = true;
        }
      }
    });
  },
};

export function Can(action: string) {
  if (UserModule.isAdmin) {
    return true;
  }
  if (UserModule.claims && UserModule.claims.permissions) {
    return UserModule.claims.permissions.includes(action);
  }
  return false;
}
