import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const digitalIdRoutes: RouteConfig = {
  path: '/digital-id',
  component: Layout,
  redirect: '/digital-id/notifications',
  meta: {
    title: 'Digital ID',
    icon: 'settings',
    requiresAuth: true,
    action: 'did:view',
  },
  children: [
    {
      path: 'notifications',
      component: () =>
        import(/* webpackChunkName: "digital-id-notifications" */ '@/views/digital-id/notifications.vue'),
      name: 'DigitalID',
      meta: {
        title: 'Notifications',
        icon: 'dashboard',
        affix: true,
        requiresAuth: true,
        action: 'did:notify',
      },
    },
  ],
};

export default digitalIdRoutes;
