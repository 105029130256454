import NProgress from 'nprogress';
import { Route } from 'vue-router';
import 'nprogress/nprogress.css';

import router from './router';
import { UserModule } from '@/store/modules/user';
import { Can } from '@/plugins/can';
import { Message } from 'element-ui';

router.beforeEach((to: Route, from: Route, next: any) => {
  let action;
  to.matched.forEach((record) => {
    action = record.meta.action;
  });
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!UserModule.authenticated) {
      next(`/login?redirect=${to.path}`); // otherwise redirect to login page
      NProgress.done();
    } else if (UserModule.authenticated && to.path === '/login') {
      next({ path: to.query.redirect || '/dashboard' });
    } else if (UserModule.authenticated && action) {
      if (UserModule.isAdmin || Can(action)) {
        next();
      } else {
        Message({
          type: 'error',
          message: 'You do not have the proper permissions for this action',
        });
        next({ path: '/dashboard' });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => NProgress.done());
