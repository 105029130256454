import { VuexModule, Module, Action, Mutation, getModule, MutationAction } from 'vuex-module-decorators';
import { setToken, removeToken } from '@/utils/cookies';
import store from '@/store';
import { auth, fire, usersCollection } from '@/firebase';

import { addBookmark, getClaims, removeBookmark } from '@/firebase/models/user';
import { Message } from 'element-ui';

export interface IUserState {
  token: string;
  id: string;
  name: string;
  claims: { admin: boolean; permissions: string[] };
  bookmarks: { [key: string]: any };
  roles: string[];
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = '';
  public id = '';
  public name = '';
  public email = '';
  public roles: string[] = [];
  public claims: any = {};
  public bookmarks: any = {};

  get authenticated() {
    return !!this.token.length;
  }

  get isAdmin() {
    return this.claims.admin;
  }

  get permissions() {
    return this.claims.permissions || [];
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_USER(userInfo: { uid: string; displayName: string; email: string; _lat: string }) {
    this.id = userInfo.uid;
    this.name = userInfo.displayName;
    this.name = userInfo.email;
    this.token = userInfo._lat;
  }

  @Mutation
  private SET_BOOKMARKS(bookmarks: object) {
    this.bookmarks = bookmarks;
  }

  @Mutation
  private SET_CLAIMS(claims: object) {
    this.claims = claims;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Action
  public async SetClaims(claims: { admin: boolean; roles: string[]; permissions: string[] }) {
    this.SET_CLAIMS(claims);
  }

  @Action
  public async Login(credentials: { email: string; password: string }) {
    await auth.setPersistence(fire.auth.Auth.Persistence.LOCAL);

    return auth.signInWithEmailAndPassword(credentials.email, credentials.password).then(async (user: any) => {
      const userRef = await usersCollection.doc(user.user.uid).get();
      const userClaims: any = await getClaims();
      const userData: any = userRef.data();
      console.log(userClaims);
      setToken(user.user._lat);
      this.SET_TOKEN(user.user._lat);

      return {
        id: user.user.uid,
        name: user.user.displayName,
        email: user.user.email,
        token: user.user._lat,
        bookmarks: userData.bookmarks,
        claims: userClaims,
      };
    });
  }

  @Action
  public ResetToken() {
    removeToken();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }

  @Action
  public SetBookmarks(bookmarks: any) {
    this.SET_BOOKMARKS(bookmarks);
  }

  @Action({ commit: 'SET_BOOKMARKS' })
  public async AddBookmark(item: { collection: string; id: string; question: string }) {
    return await addBookmark(item);
  }

  @Action({ commit: 'SET_BOOKMARKS' })
  public async RemoveBookmark(id: string) {
    return await removeBookmark(id);
  }

  @Action
  public async setUser({
    uid,
    displayName,
    email,
    _lat,
  }: {
    uid: string;
    displayName: string;
    email: string;
    _lat: string;
  }) {
    this.SET_USER({
      uid,
      displayName,
      email,
      _lat,
    });
  }

  @Action
  public async Logout() {
    if (this.token === '') {
      throw Error('Logout: token is undefined!');
    }
    await auth.signOut().catch();
    // .then(() => {
    //   removeToken();
    //   this.SET_TOKEN('');
    //   this.SET_ROLES([]);
    // });
    removeToken();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }

  @MutationAction({ mutate: ['name', 'email'] })
  async updateProfile(email: string, name: string) {
    if (auth.currentUser) {
      await auth.currentUser
        .updateEmail(email)
        .then(() => {
          Message({
            type: 'success',
            message: 'Email address successfully updated',
          });
        })
        .catch(() => {
          Message({
            type: 'error',
            message: 'Something went wrong, please try again later',
          });
          return {
            name: this.name,
            email: this.email,
          };
        });
      await auth.currentUser.sendEmailVerification();
      await auth.currentUser
        .updateProfile({
          displayName: name,
          photoURL: '',
        })
        .catch(() => {
          Message({
            type: 'error',
            message: 'Something went wrong, please try again later',
          });
          return {
            name: this.name,
            email: this.email,
          };
        });
      return {
        name,
        email,
      };
    } else {
      Message({
        type: 'error',
        message: 'Something went wrong, please try again later',
      });
      return {
        name: this.name,
        email: this.email,
      };
    }
  }
}

export const UserModule = getModule(User);
