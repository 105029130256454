import { DirectiveOptions } from 'vue';
import { UserModule } from '@/store/modules/user';

export const permission: DirectiveOptions = {
  inserted(el, binding) {
    const { value } = binding;
    const permissions = UserModule.permissions || [];
    if (value && value instanceof Array && value.length > 0) {
      const permissionActions = value;
      console.log(permissions);
      const hasPermission = permissions.some((role: string) => {
        console.log(role);
        return permissionActions.includes(role);
      });
      if (!UserModule.isAdmin) {
        if (!hasPermission) {
          el.style.display = 'none';
        }
      }
    } else {
      throw new Error("need permission! Like v-permission=\"['admin','editor']\"");
    }
  },
};
